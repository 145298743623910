/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { Link } from 'gatsby';
import { FirebaseAppProvider } from 'reactfire';
import { PrismicProvider } from '@prismicio/react';
import FirebaseJunk from './src/firebase';
// import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

import { CartProvider } from './src/hooks/useCart';
import { AnnouncementProvider } from './src/hooks/useAnnouncement';
import './src/css/main.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import firebaseConfig from './firebaseConfig';

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
});

export function wrapRootElement({ element }) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseJunk>
        <CartProvider>
          <AnnouncementProvider>
            <PrismicProvider internalLinkComponent={Link}>{element}</PrismicProvider>
          </AnnouncementProvider>
        </CartProvider>
      </FirebaseJunk>
    </FirebaseAppProvider>
  );
}
